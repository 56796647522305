define("discourse/plugins/chat/discourse/helpers/tonable-emoji-url", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = tonableEmojiUrl;
  function tonableEmojiUrl(emoji, scale) {
    if (!emoji.tonable || scale === 1) {
      return emoji.url;
    }
    return emoji.url.split(".png")[0] + `/${scale}.png`;
  }
});