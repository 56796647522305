define("discourse/plugins/chat/discourse/modifiers/chat/later-fn", ["exports", "@ember/destroyable", "@ember/runloop", "ember-modifier", "discourse-common/lib/later"], function (_exports, _destroyable, _runloop, _emberModifier, _later) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class ChatLaterFn extends _emberModifier.default {
    constructor(owner, args) {
      super(owner, args);
      (0, _destroyable.registerDestructor)(this, instance => instance.cleanup());
    }
    modify(element, _ref) {
      let [fn, delay] = _ref;
      this.handler = (0, _later.default)(() => {
        fn?.(element);
      }, delay);
    }
    cleanup() {
      (0, _runloop.cancel)(this.handler);
    }
  }
  _exports.default = ChatLaterFn;
});