define("discourse/plugins/chat/discourse/helpers/tonable-emoji-title", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = tonableEmojiTitle;
  function tonableEmojiTitle(emoji, diversity) {
    if (!emoji.tonable || diversity === 1) {
      return `:${emoji.name}:`;
    }
    return `:${emoji.name}:t${diversity}:`;
  }
});