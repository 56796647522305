define("discourse/plugins/chat/discourse/lib/get-user-chat-separate-sidebar-mode", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.getUserChatSeparateSidebarMode = getUserChatSeparateSidebarMode;
  function getUserChatSeparateSidebarMode(user) {
    let mode = user?.get("user_option.chat_separate_sidebar_mode");
    if (mode === "default") {
      mode = user.siteSettings.chat_separate_sidebar_mode;
    }
    return {
      never: "never" === mode,
      always: "always" === mode,
      fullscreen: "fullscreen" === mode
    };
  }
});