define("discourse/plugins/chat/discourse/components/chat/message-creator/lib/iterate-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.getNext = getNext;
  _exports.getPrevious = getPrevious;
  function getNext(list) {
    let current = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
    if (list.length === 0) {
      return null;
    }
    list = list.filterBy("enabled");
    if (current?.identifier) {
      const currentIndex = list.mapBy("identifier").indexOf(current?.identifier);
      if (currentIndex < list.length - 1) {
        return list.objectAt(currentIndex + 1);
      } else {
        return list[0];
      }
    } else {
      return list[0];
    }
  }
  function getPrevious(list) {
    let current = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
    if (list.length === 0) {
      return null;
    }
    list = list.filterBy("enabled");
    if (current?.identifier) {
      const currentIndex = list.mapBy("identifier").indexOf(current?.identifier);
      if (currentIndex > 0) {
        return list.objectAt(currentIndex - 1);
      } else {
        return list.objectAt(list.length - 1);
      }
    } else {
      return list.objectAt(list.length - 1);
    }
  }
});