define("discourse/plugins/chat/discourse/lib/chat-user-preferences", ["exports", "discourse/plugins/chat/discourse/controllers/preferences-chat"], function (_exports, _preferencesChat) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.hasChatIndicator = hasChatIndicator;
  function hasChatIndicator(user) {
    const pref = user.user_option.chat_header_indicator_preference;
    return {
      ALL_NEW: pref === _preferencesChat.HEADER_INDICATOR_PREFERENCE_ALL_NEW,
      DM_AND_MENTIONS: pref === _preferencesChat.HEADER_INDICATOR_PREFERENCE_DM_AND_MENTIONS,
      ONLY_MENTIONS: pref === _preferencesChat.HEADER_INDICATOR_PREFERENCE_ONLY_MENTIONS,
      NEVER: pref === _preferencesChat.HEADER_INDICATOR_PREFERENCE_NEVER
    };
  }
});