define("discourse/plugins/chat/discourse/templates/chat-channel-thread", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <Chat::Routes::ChannelThread
    @thread={{this.model}}
    @targetMessageId={{this.targetMessageId}}
  />
  */
  {
    "id": "dBCndVuI",
    "block": "[[[8,[39,0],null,[[\"@thread\",\"@targetMessageId\"],[[30,0,[\"model\"]],[30,0,[\"targetMessageId\"]]]],null]],[],false,[\"chat/routes/channel-thread\"]]",
    "moduleName": "discourse/plugins/chat/discourse/templates/chat-channel-thread.hbs",
    "isStrictMode": false
  });
});