define("discourse/plugins/chat/discourse/components/chat-thread-tracking-dropdown", ["exports", "@ember-decorators/component", "select-kit/components/notifications-button", "select-kit/components/select-kit", "discourse/plugins/chat/discourse/lib/chat-notification-levels"], function (_exports, _component, _notificationsButton, _selectKit, _chatNotificationLevels) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const ChatThreadTrackingDropdown = dt7948.c(class ChatThreadTrackingDropdown extends _notificationsButton.default {
    content = (() => _chatNotificationLevels.threadNotificationButtonLevels)();
  }, [(0, _selectKit.pluginApiIdentifiers)("thread-notifications-button"), (0, _selectKit.selectKitOptions)({
    i18nPrefix: "chat.thread.notifications",
    showFullTitle: false,
    btnCustomClasses: "btn-flat",
    customStyle: true
  }), (0, _component.classNames)("thread-notifications-button")]);
  var _default = _exports.default = ChatThreadTrackingDropdown;
});