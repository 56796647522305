define("discourse/plugins/chat/discourse/services/chat-subscriptions-manager", ["exports", "@ember/service", "discourse/lib/notification-levels", "discourse-common/utils/decorators", "discourse-i18n", "discourse/plugins/chat/discourse/models/chat-channel", "discourse/plugins/chat/discourse/models/chat-channel-archive"], function (_exports, _service, _notificationLevels, _decorators, _discourseI18n, _chatChannel, _chatChannelArchive) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class ChatSubscriptionsManager extends _service.default {
    static #_ = (() => dt7948.g(this.prototype, "store", [_service.service]))();
    #store = (() => (dt7948.i(this, "store"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "chatChannelsManager", [_service.service]))();
    #chatChannelsManager = (() => (dt7948.i(this, "chatChannelsManager"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "chatTrackingStateManager", [_service.service]))();
    #chatTrackingStateManager = (() => (dt7948.i(this, "chatTrackingStateManager"), void 0))();
    static #_4 = (() => dt7948.g(this.prototype, "currentUser", [_service.service]))();
    #currentUser = (() => (dt7948.i(this, "currentUser"), void 0))();
    static #_5 = (() => dt7948.g(this.prototype, "appEvents", [_service.service]))();
    #appEvents = (() => (dt7948.i(this, "appEvents"), void 0))();
    static #_6 = (() => dt7948.g(this.prototype, "chat", [_service.service]))();
    #chat = (() => (dt7948.i(this, "chat"), void 0))();
    static #_7 = (() => dt7948.g(this.prototype, "dialog", [_service.service]))();
    #dialog = (() => (dt7948.i(this, "dialog"), void 0))();
    static #_8 = (() => dt7948.g(this.prototype, "router", [_service.service]))();
    #router = (() => (dt7948.i(this, "router"), void 0))();
    _channelSubscriptions = (() => new Set())();
    startChannelSubscription(channel) {
      if (channel.currentUserMembership.muted || this._channelSubscriptions.has(channel.id)) {
        return;
      }
      this._channelSubscriptions.add(channel.id);
      this._startChannelMentionsSubscription(channel);
      if (!channel.isDirectMessageChannel) {
        this._startKickFromChannelSubscription(channel);
      }
      this._startChannelNewMessagesSubscription(channel);
    }
    stopChannelSubscription(channel) {
      this.messageBus.unsubscribe(`/chat/${channel.id}/new-messages`, this._onNewMessages);
      if (!channel.isDirectMessageChannel) {
        this.messageBus.unsubscribe(`/chat/${channel.id}/new-mentions`, this._onNewMentions);
        this.messageBus.unsubscribe(`/chat/${channel.id}/kick`, this._onKickFromChannel);
      }
      this._channelSubscriptions.delete(channel.id);
    }
    startChannelsSubscriptions(messageBusIds) {
      this._startNewChannelSubscription(messageBusIds.new_channel);
      this._startChannelArchiveStatusSubscription(messageBusIds.archive_status);
      this._startUserTrackingStateSubscription(messageBusIds.user_tracking_state);
      this._startChannelsEditsSubscription(messageBusIds.channel_edits);
      this._startChannelsStatusChangesSubscription(messageBusIds.channel_status);
      this._startChannelsMetadataChangesSubscription(messageBusIds.channel_metadata);
    }
    stopChannelsSubscriptions() {
      this._stopNewChannelSubscription();
      this._stopChannelArchiveStatusSubscription();
      this._stopUserTrackingStateSubscription();
      this._stopChannelsEditsSubscription();
      this._stopChannelsStatusChangesSubscription();
      this._stopChannelsMetadataChangesSubscription();
      (this.chatChannelsManager.channels || []).forEach(channel => {
        this.stopChannelSubscription(channel);
      });
    }
    _startChannelArchiveStatusSubscription(lastId) {
      if (this.currentUser.admin) {
        this.messageBus.subscribe("/chat/channel-archive-status", this._onChannelArchiveStatusUpdate, lastId);
      }
    }
    _stopChannelArchiveStatusSubscription() {
      if (this.currentUser.admin) {
        this.messageBus.unsubscribe("/chat/channel-archive-status", this._onChannelArchiveStatusUpdate);
      }
    }
    _startChannelMentionsSubscription(channel) {
      this.messageBus.subscribe(`/chat/${channel.id}/new-mentions`, this._onNewMentions, channel.meta.message_bus_last_ids.new_mentions);
    }
    _startKickFromChannelSubscription(channel) {
      this.messageBus.subscribe(`/chat/${channel.id}/kick`, this._onKickFromChannel, channel.meta.message_bus_last_ids.kick);
    }
    _onChannelArchiveStatusUpdate(busData) {
      // we don't want to fetch a channel we don't have locally because archive status changed
      this.chatChannelsManager.find(busData.chat_channel_id, {
        fetchIfNotFound: false
      }).then(channel => {
        if (!channel) {
          return;
        }
        channel.archive = _chatChannelArchive.default.create(busData);
      });
    }
    static #_9 = (() => dt7948.n(this.prototype, "_onChannelArchiveStatusUpdate", [_decorators.bind]))();
    _onNewMentions(busData) {
      this.chatChannelsManager.find(busData.channel_id).then(channel => {
        const membership = channel.currentUserMembership;
        if (busData.message_id > membership?.lastReadMessageId) {
          channel.tracking.mentionCount++;
        }
      });
    }
    static #_10 = (() => dt7948.n(this.prototype, "_onNewMentions", [_decorators.bind]))();
    _onKickFromChannel(busData) {
      this.chatChannelsManager.find(busData.channel_id).then(channel => {
        if (this.chat.activeChannel.id === channel.id) {
          this.dialog.alert({
            message: _discourseI18n.default.t("chat.kicked_from_channel"),
            didConfirm: () => {
              this.chatChannelsManager.remove(channel);
              const firstChannel = this.chatChannelsManager.publicMessageChannels[0];
              if (firstChannel) {
                this.router.transitionTo("chat.channel", ...firstChannel.routeModels);
              } else {
                this.router.transitionTo("chat.browse");
              }
            }
          });
        } else {
          this.chatChannelsManager.remove(channel);
        }
      });
    }
    static #_11 = (() => dt7948.n(this.prototype, "_onKickFromChannel", [_decorators.bind]))();
    _startChannelNewMessagesSubscription(channel) {
      this.messageBus.subscribe(`/chat/${channel.id}/new-messages`, this._onNewMessages, channel.meta.message_bus_last_ids.new_messages);
    }
    _onNewMessages(busData) {
      switch (busData.type) {
        case "channel":
          this._onNewChannelMessage(busData);
          break;
        case "thread":
          this._onNewThreadMessage(busData);
          break;
      }
    }
    static #_12 = (() => dt7948.n(this.prototype, "_onNewMessages", [_decorators.bind]))();
    _onNewChannelMessage(busData) {
      this.chatChannelsManager.find(busData.channel_id).then(channel => {
        channel.lastMessage = busData.message;
        const user = busData.message.user;
        if (user.id === this.currentUser.id) {
          // User sent message, update tracking state to no unread
          channel.currentUserMembership.lastReadMessageId = channel.lastMessage.id;
        } else {
          // Ignored user sent message, update tracking state to no unread
          if (this.currentUser.ignored_users.includes(user.username)) {
            channel.currentUserMembership.lastReadMessageId = channel.lastMessage.id;
          } else {
            if (channel.lastMessage.id > (channel.currentUserMembership.lastReadMessageId || 0)) {
              channel.tracking.unreadCount++;
            }

            // Thread should be considered unread if not already.
            if (busData.thread_id && channel.threadingEnabled) {
              channel.threadsManager.find(channel.id, busData.thread_id).then(thread => {
                if (thread.currentUserMembership) {
                  channel.threadsManager.markThreadUnread(busData.thread_id, busData.message.created_at);
                  this._updateActiveLastViewedAt(channel);
                }
              });
            }
          }
        }
      });
    }
    _onNewThreadMessage(busData) {
      this.chatChannelsManager.find(busData.channel_id).then(channel => {
        if (!channel.threadingEnabled && !busData.force_thread) {
          return;
        }
        channel.threadsManager.find(busData.channel_id, busData.thread_id).then(thread => {
          thread.lastMessageId = busData.message.id;
          if (busData.message.user.id === this.currentUser.id) {
            // Thread should no longer be considered unread.
            if (thread.currentUserMembership) {
              channel.threadsManager.unreadThreadOverview.delete(parseInt(busData.thread_id, 10));
              thread.currentUserMembership.lastReadMessageId = busData.message.id;
            }
          } else {
            // Ignored user sent message, update tracking state to no unread
            if (this.currentUser.ignored_users.includes(busData.message.user.username)) {
              if (thread.currentUserMembership) {
                thread.currentUserMembership.lastReadMessageId = busData.message.id;
              }
            } else {
              // Message from other user. Increment unread for thread tracking state.
              if (thread.currentUserMembership && busData.message.id > (thread.currentUserMembership.lastReadMessageId || 0) && !thread.currentUserMembership.isQuiet) {
                channel.threadsManager.markThreadUnread(busData.thread_id, busData.message.created_at);
                if (thread.currentUserMembership.notificationLevel === _notificationLevels.NotificationLevels.WATCHING) {
                  thread.tracking.watchedThreadsUnreadCount++;
                  channel.tracking.watchedThreadsUnreadCount++;
                } else {
                  thread.tracking.unreadCount++;
                }
                this._updateActiveLastViewedAt(channel);
              }
            }
          }
        });
      });
    }

    // If the user is currently looking at this channel via activeChannel, we don't want the unread
    // indicator to show in the sidebar for unread threads (since that is based on the lastViewedAt).
    _updateActiveLastViewedAt(channel) {
      if (this.chat.activeChannel?.id === channel.id) {
        channel.updateLastViewedAt();
      }
    }
    _startUserTrackingStateSubscription(lastId) {
      if (!this.currentUser) {
        return;
      }
      this.messageBus.subscribe(`/chat/user-tracking-state/${this.currentUser.id}`, this._onUserTrackingStateUpdate, lastId);
      this.messageBus.subscribe(`/chat/bulk-user-tracking-state/${this.currentUser.id}`, this._onBulkUserTrackingStateUpdate, lastId);
    }
    _stopUserTrackingStateSubscription() {
      if (!this.currentUser) {
        return;
      }
      this.messageBus.unsubscribe(`/chat/user-tracking-state/${this.currentUser.id}`, this._onUserTrackingStateUpdate);
      this.messageBus.unsubscribe(`/chat/bulk-user-tracking-state/${this.currentUser.id}`, this._onBulkUserTrackingStateUpdate);
    }
    _onBulkUserTrackingStateUpdate(busData) {
      Object.keys(busData).forEach(channelId => {
        this._updateChannelTrackingData(channelId, busData[channelId]);
      });
    }
    static #_13 = (() => dt7948.n(this.prototype, "_onBulkUserTrackingStateUpdate", [_decorators.bind]))();
    _onUserTrackingStateUpdate(busData) {
      this._updateChannelTrackingData(busData.channel_id, busData);
    }
    static #_14 = (() => dt7948.n(this.prototype, "_onUserTrackingStateUpdate", [_decorators.bind]))();
    _updateChannelTrackingData(channelId, busData) {
      this.chatChannelsManager.find(channelId).then(channel => {
        if (!busData.thread_id) {
          channel.currentUserMembership.lastReadMessageId = busData.last_read_message_id;
        }
        channel.tracking.unreadCount = busData.unread_count;
        channel.tracking.mentionCount = busData.mention_count;
        channel.tracking.watchedThreadsUnreadCount = busData.watched_threads_unread_count;
        if (busData.hasOwnProperty("unread_thread_overview") && channel.threadingEnabled) {
          channel.threadsManager.unreadThreadOverview = busData.unread_thread_overview;
        }
        if (busData.thread_id && busData.hasOwnProperty("thread_tracking") && channel.threadingEnabled) {
          channel.threadsManager.find(channelId, busData.thread_id).then(thread => {
            if (thread.currentUserMembership && !thread.currentUserMembership.isQuiet) {
              thread.currentUserMembership.lastReadMessageId = busData.last_read_message_id;
              thread.tracking.unreadCount = busData.thread_tracking.unread_count;
              thread.tracking.mentionCount = busData.thread_tracking.mention_count;
              thread.tracking.watchedThreadsUnreadCount = busData.thread_tracking.watched_threads_unread_count;
            }
          });
        }
      });
    }
    static #_15 = (() => dt7948.n(this.prototype, "_updateChannelTrackingData", [_decorators.bind]))();
    _startNewChannelSubscription(lastId) {
      this.messageBus.subscribe("/chat/new-channel", this._onNewChannelSubscription, lastId);
    }
    _stopNewChannelSubscription() {
      this.messageBus.unsubscribe("/chat/new-channel", this._onNewChannelSubscription);
    }
    _onNewChannelSubscription(data) {
      this.chatChannelsManager.find(data.channel.id).then(channel => {
        // we need to refresh here to have correct last message ids
        channel.meta = data.channel.meta;
        channel.currentUserMembership = data.channel.current_user_membership;
        if (channel.isDirectMessageChannel && !channel.currentUserMembership.following) {
          channel.tracking.unreadCount = 1;
        }
        this.chatChannelsManager.follow(channel);
      });
    }
    static #_16 = (() => dt7948.n(this.prototype, "_onNewChannelSubscription", [_decorators.bind]))();
    _startChannelsMetadataChangesSubscription(lastId) {
      this.messageBus.subscribe("/chat/channel-metadata", this._onChannelMetadata, lastId);
    }
    _startChannelsEditsSubscription(lastId) {
      this.messageBus.subscribe("/chat/channel-edits", this._onChannelEdits, lastId);
    }
    _startChannelsStatusChangesSubscription(lastId) {
      this.messageBus.subscribe("/chat/channel-status", this._onChannelStatus, lastId);
    }
    _stopChannelsStatusChangesSubscription() {
      this.messageBus.unsubscribe("/chat/channel-status", this._onChannelStatus);
    }
    _stopChannelsEditsSubscription() {
      this.messageBus.unsubscribe("/chat/channel-edits", this._onChannelEdits);
    }
    _stopChannelsMetadataChangesSubscription() {
      this.messageBus.unsubscribe("/chat/channel-metadata", this._onChannelMetadata);
    }
    _onChannelMetadata(busData) {
      this.chatChannelsManager.find(busData.chat_channel_id, {
        fetchIfNotFound: false
      }).then(channel => {
        if (channel) {
          channel.membershipsCount = busData.memberships_count;
          this.appEvents.trigger("chat:refresh-channel-members");
        }
      });
    }
    static #_17 = (() => dt7948.n(this.prototype, "_onChannelMetadata", [_decorators.bind]))();
    _onChannelEdits(busData) {
      this.chatChannelsManager.find(busData.chat_channel_id).then(channel => {
        if (channel) {
          channel.title = busData.name;
          channel.description = busData.description;
          channel.slug = busData.slug;
        }
      });
    }
    static #_18 = (() => dt7948.n(this.prototype, "_onChannelEdits", [_decorators.bind]))();
    _onChannelStatus(busData) {
      this.chatChannelsManager.find(busData.chat_channel_id).then(channel => {
        channel.status = busData.status;

        // it is not possible for the user to set their last read message id
        // if the channel has been archived, because all the messages have
        // been deleted. we don't want them seeing the blue dot anymore so
        // just completely reset the unreads
        if (busData.status === _chatChannel.CHANNEL_STATUSES.archived) {
          channel.tracking.reset();
        }
      });
    }
    static #_19 = (() => dt7948.n(this.prototype, "_onChannelStatus", [_decorators.bind]))();
  }
  _exports.default = ChatSubscriptionsManager;
});