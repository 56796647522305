define("discourse/plugins/chat/discourse/lib/check-message-visibility", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.checkMessageBottomVisibility = checkMessageBottomVisibility;
  _exports.checkMessageTopVisibility = checkMessageTopVisibility;
  function checkMessageBottomVisibility(list, message) {
    const distanceToTop = window.pageYOffset + list.getBoundingClientRect().top;
    const bounding = message.getBoundingClientRect();
    return bounding.bottom - distanceToTop <= list.clientHeight + 1;
  }
  function checkMessageTopVisibility(list, message) {
    const distanceToTop = window.pageYOffset + list.getBoundingClientRect().top;
    const bounding = message.getBoundingClientRect();
    return bounding.top - distanceToTop >= -1;
  }
});