define("discourse/plugins/chat/discourse/modifiers/chat/on-resize", ["exports", "@ember/destroyable", "@ember/runloop", "ember-modifier"], function (_exports, _destroyable, _runloop, _emberModifier) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class ChatOnResize extends _emberModifier.default {
    constructor(owner, args) {
      super(owner, args);
      (0, _destroyable.registerDestructor)(this, instance => instance.cleanup());
    }
    modify(element, _ref) {
      let [fn, options = {}] = _ref;
      this.resizeObserver = new ResizeObserver(entries => {
        this.throttleHandler = (0, _runloop.throttle)(this, fn, entries, options.delay ?? 0, options.immediate ?? false);
      });
      this.resizeObserver.observe(element);
    }
    cleanup() {
      (0, _runloop.cancel)(this.throttleHandler);
      this.resizeObserver?.disconnect();
    }
  }
  _exports.default = ChatOnResize;
});