define("discourse/plugins/chat/discourse/initializers/chat-sidebar", ["exports", "@glimmer/tracking", "@ember/service", "@ember/string", "@ember/template", "discourse/components/user-status-message", "discourse/helpers/decorate-username-selector", "discourse/lib/plugin-api", "discourse/lib/text", "discourse/lib/utilities", "discourse-common/lib/avatar-utils", "discourse-common/utils/decorators", "discourse-i18n", "discourse/plugins/chat/discourse/components/chat/modal/new-message", "discourse/plugins/chat/discourse/lib/init-sidebar-state"], function (_exports, _tracking, _service, _string, _template, _userStatusMessage, _decorateUsernameSelector, _pluginApi, _text, _utilities, _avatarUtils, _decorators, _discourseI18n, _newMessage, _initSidebarState) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    name: "chat-sidebar",
    initialize(container) {
      this.chatService = container.lookup("service:chat");
      if (!this.chatService.userCanChat) {
        return;
      }
      this.siteSettings = container.lookup("service:site-settings");
      this.currentUser = container.lookup("service:current-user");
      (0, _pluginApi.withPluginApi)("1.8.0", api => {
        const chatStateManager = container.lookup("service:chat-state-manager");
        api.addSidebarPanel(BaseCustomSidebarPanel => class ChatSidebarPanel extends BaseCustomSidebarPanel {
          key = (() => _initSidebarState.CHAT_PANEL)();
          switchButtonLabel = (() => _discourseI18n.default.t("sidebar.panels.chat.label"))();
          switchButtonIcon = "d-chat";
          get switchButtonDefaultUrl() {
            return chatStateManager.lastKnownChatURL || "/chat";
          }
        });
        (0, _initSidebarState.initSidebarState)(api, api.getCurrentUser());
      });
      (0, _pluginApi.withPluginApi)("1.3.0", api => {
        const chatChannelsManager = container.lookup("service:chat-channels-manager");
        const chatStateManager = container.lookup("service:chat-state-manager");
        api.addSidebarSection((BaseCustomSidebarSection, BaseCustomSidebarSectionLink) => {
          const SidebarChatMyThreadsSectionLink = class extends BaseCustomSidebarSectionLink {
            route = "chat.threads";
            text = (() => _discourseI18n.default.t("chat.my_threads.title"))();
            title = (() => _discourseI18n.default.t("chat.my_threads.title"))();
            name = "user-threads";
            prefixType = "icon";
            prefixValue = "discourse-threads";
            suffixType = "icon";
            suffixCSSClass = "unread";
            constructor() {
              super(...arguments);
              if (container.isDestroyed) {
                return;
              }
            }
            get suffixValue() {
              return chatChannelsManager.publicMessageChannels.some(channel => channel.unreadThreadsCount > 0) ? "circle" : "";
            }
          };
          const SidebarChatMyThreadsSection = class extends BaseCustomSidebarSection {
            static #_ = (() => dt7948.g(this.prototype, "chatChannelsManager", [_service.service]))();
            #chatChannelsManager = (() => (dt7948.i(this, "chatChannelsManager"), void 0))();
            // we only show `My Threads` link
            hideSectionHeader = true;
            name = "user-threads";

            // sidebar API doesn’t let you have undefined values
            // even if you don't show the section’s header
            title = "";
            get links() {
              return [new SidebarChatMyThreadsSectionLink()];
            }
            get text() {
              return null;
            }
            get displaySection() {
              return this.chatChannelsManager.hasThreadedChannels;
            }
          };
          return SidebarChatMyThreadsSection;
        }, _initSidebarState.CHAT_PANEL);
        if (this.siteSettings.enable_public_channels) {
          api.addSidebarSection((BaseCustomSidebarSection, BaseCustomSidebarSectionLink) => {
            const SidebarChatChannelsSectionLink = class extends BaseCustomSidebarSectionLink {
              constructor(_ref) {
                let {
                  channel,
                  chatService
                } = _ref;
                super(...arguments);
                this.channel = channel;
                this.chatService = chatService;
                this.chatStateManager = chatStateManager;
              }
              get name() {
                return (0, _string.dasherize)(this.channel.slugifiedTitle);
              }
              get classNames() {
                const classes = [];
                if (this.channel.currentUserMembership.muted) {
                  classes.push("sidebar-section-link--muted");
                }
                if (this.channel.id === this.chatService.activeChannel?.id && (this.chatStateManager.isDrawerExpanded || this.chatStateManager.isFullPageActive)) {
                  classes.push("sidebar-section-link--active");
                }
                classes.push(`channel-${this.channel.id}`);
                return classes.join(" ");
              }
              get route() {
                return "chat.channel";
              }
              get models() {
                return this.channel.routeModels;
              }
              get text() {
                return (0, _template.htmlSafe)((0, _text.emojiUnescape)(this.channel.escapedTitle));
              }
              get prefixType() {
                return "icon";
              }
              get prefixValue() {
                return "d-chat";
              }
              get prefixColor() {
                return this.channel.chatable.color;
              }
              get title() {
                return this.channel.escapedDescription ? (0, _template.htmlSafe)(this.channel.escapedDescription) : `${this.channel.escapedTitle} ${_discourseI18n.default.t("chat.title")}`;
              }
              get prefixBadge() {
                return this.channel.chatable.read_restricted ? "lock" : "";
              }
              get suffixType() {
                return "icon";
              }
              get suffixValue() {
                return this.channel.tracking.unreadCount > 0 ||
                // We want to do this so we don't show a blue dot if the user is inside
                // the channel and a new unread thread comes in.
                this.chatService.activeChannel?.id !== this.channel.id && this.channel.unreadThreadsCountSinceLastViewed > 0 ? "circle" : "";
              }
              get suffixCSSClass() {
                return this.channel.tracking.mentionCount > 0 ? "urgent" : "unread";
              }
            };
            const SidebarChatChannelsSection = class extends BaseCustomSidebarSection {
              static #_ = (() => dt7948.g(this.prototype, "currentUser", [_service.service]))();
              #currentUser = (() => (dt7948.i(this, "currentUser"), void 0))();
              static #_2 = (() => dt7948.g(this.prototype, "chatStateManager", [_service.service]))();
              #chatStateManager = (() => (dt7948.i(this, "chatStateManager"), void 0))();
              static #_3 = (() => dt7948.g(this.prototype, "currentUserCanJoinPublicChannels", [_tracking.tracked], function () {
                return this.currentUser && (this.currentUser.staff || this.currentUser.has_joinable_public_channels);
              }))();
              #currentUserCanJoinPublicChannels = (() => (dt7948.i(this, "currentUserCanJoinPublicChannels"), void 0))();
              constructor() {
                super(...arguments);
                if (container.isDestroyed) {
                  return;
                }
                this.chatService = container.lookup("service:chat");
                this.chatChannelsManager = container.lookup("service:chat-channels-manager");
                this.router = container.lookup("service:router");
              }
              get sectionLinks() {
                return this.chatChannelsManager.publicMessageChannels.map(channel => new SidebarChatChannelsSectionLink({
                  channel,
                  chatService: this.chatService
                }));
              }
              get name() {
                return "chat-channels";
              }
              get title() {
                return _discourseI18n.default.t("chat.chat_channels");
              }
              get text() {
                return _discourseI18n.default.t("chat.chat_channels");
              }
              get actions() {
                return [{
                  id: "browseChannels",
                  title: _discourseI18n.default.t("chat.channels_list_popup.browse"),
                  action: () => this.router.transitionTo("chat.browse.open")
                }];
              }
              get actionsIcon() {
                return "pencil";
              }
              get links() {
                return this.sectionLinks;
              }
              get displaySection() {
                return this.chatStateManager.hasPreloadedChannels && (this.sectionLinks.length > 0 || this.currentUserCanJoinPublicChannels);
              }
            };
            return SidebarChatChannelsSection;
          }, _initSidebarState.CHAT_PANEL);
        }
        api.addSidebarSection((BaseCustomSidebarSection, BaseCustomSidebarSectionLink) => {
          const SidebarChatDirectMessagesSectionLink = class extends BaseCustomSidebarSectionLink {
            route = "chat.channel";
            suffixType = "icon";
            suffixCSSClass = "urgent";
            hoverType = "icon";
            hoverValue = "xmark";
            hoverTitle = (() => _discourseI18n.default.t("chat.direct_messages.close"))();
            constructor(_ref2) {
              let {
                channel,
                chatService,
                currentUser
              } = _ref2;
              super(...arguments);
              this.channel = channel;
              this.chatService = chatService;
              this.currentUser = currentUser;
              this.chatStateManager = chatStateManager;
              if (this.oneOnOneMessage) {
                const user = this.channel.chatable.users[0];
                if (user.username !== _discourseI18n.default.t("chat.deleted_chat_username")) {
                  user.statusManager.trackStatus();
                }
              }
            }
            willDestroy() {
              if (this.oneOnOneMessage) {
                this.channel.chatable.users[0].statusManager.stopTrackingStatus();
              }
            }
            static #_ = (() => dt7948.n(this.prototype, "willDestroy", [_decorators.bind]))();
            get oneOnOneMessage() {
              return this.channel.chatable.users.length === 1;
            }
            get contentComponentArgs() {
              return this.channel.chatable.users[0].get("status");
            }
            get contentComponent() {
              if (this.oneOnOneMessage) {
                return _userStatusMessage.default;
              }
            }
            get name() {
              return this.channel.slugifiedTitle;
            }
            get classNames() {
              const classes = [];
              if (this.channel.currentUserMembership.muted) {
                classes.push("sidebar-section-link--muted");
              }
              if (this.channel.id === this.chatService.activeChannel?.id && (this.chatStateManager.isDrawerExpanded || this.chatStateManager.isFullPageActive)) {
                classes.push("sidebar-section-link--active");
              }
              classes.push(`channel-${this.channel.id}`);
              return classes.join(" ");
            }
            get models() {
              return this.channel.routeModels;
            }
            get title() {
              if (this.channel.chatable.group) {
                return _discourseI18n.default.t("chat.placeholder_channel", {
                  channelName: this.channel.escapedTitle
                });
              } else {
                return _discourseI18n.default.t("chat.placeholder_users", {
                  commaSeparatedNames: this.channel.escapedTitle
                });
              }
            }
            get text() {
              if (this.channel.chatable.group) {
                return this.channel.title;
              } else {
                const username = this.channel.escapedTitle.replaceAll("@", "");
                return (0, _template.htmlSafe)(`${(0, _utilities.escapeExpression)(username)}${(0, _decorateUsernameSelector.decorateUsername)((0, _utilities.escapeExpression)(username))}`);
              }
            }
            get prefixType() {
              if (this.channel.chatable.group) {
                return "text";
              } else {
                return "image";
              }
            }
            get prefixValue() {
              if (this.channel.chatable.group) {
                return this.channel.membershipsCount;
              } else {
                return (0, _avatarUtils.avatarUrl)(this.channel.chatable.users[0].avatar_template, "tiny");
              }
            }
            get prefixCSSClass() {
              const activeUsers = this.chatService.presenceChannel.users;
              const user = this.channel.chatable.users[0];
              if (!!activeUsers?.findBy("id", user?.id) || !!activeUsers?.findBy("username", user?.username)) {
                return "active";
              }
              return "";
            }
            get suffixValue() {
              return this.channel.tracking.unreadCount > 0 ? "circle" : "";
            }
            get hoverAction() {
              return event => {
                event.stopPropagation();
                event.preventDefault();
                this.chatService.unfollowChannel(this.channel);
              };
            }
          };
          const SidebarChatDirectMessagesSection = class extends BaseCustomSidebarSection {
            static #_ = (() => dt7948.g(this.prototype, "site", [_service.service]))();
            #site = (() => (dt7948.i(this, "site"), void 0))();
            static #_2 = (() => dt7948.g(this.prototype, "modal", [_service.service]))();
            #modal = (() => (dt7948.i(this, "modal"), void 0))();
            static #_3 = (() => dt7948.g(this.prototype, "router", [_service.service]))();
            #router = (() => (dt7948.i(this, "router"), void 0))();
            static #_4 = (() => dt7948.g(this.prototype, "currentUser", [_service.service]))();
            #currentUser = (() => (dt7948.i(this, "currentUser"), void 0))();
            static #_5 = (() => dt7948.g(this.prototype, "chatStateManager", [_service.service]))();
            #chatStateManager = (() => (dt7948.i(this, "chatStateManager"), void 0))();
            static #_6 = (() => dt7948.g(this.prototype, "userCanDirectMessage", [_tracking.tracked], function () {
              return this.chatService.userCanDirectMessage;
            }))();
            #userCanDirectMessage = (() => (dt7948.i(this, "userCanDirectMessage"), void 0))();
            constructor() {
              super(...arguments);
              if (container.isDestroyed) {
                return;
              }
              this.chatService = container.lookup("service:chat");
              this.chatChannelsManager = container.lookup("service:chat-channels-manager");
            }
            get sectionLinks() {
              return this.chatChannelsManager.truncatedDirectMessageChannels.map(channel => new SidebarChatDirectMessagesSectionLink({
                channel,
                chatService: this.chatService,
                currentUser: this.currentUser
              }));
            }
            get name() {
              return "chat-dms";
            }
            get title() {
              return _discourseI18n.default.t("chat.direct_messages.title");
            }
            get text() {
              return _discourseI18n.default.t("chat.direct_messages.title");
            }
            get actions() {
              if (!this.userCanDirectMessage) {
                return [];
              }
              return [{
                id: "startDm",
                title: _discourseI18n.default.t("chat.direct_messages.new"),
                action: () => {
                  this.modal.show(_newMessage.default);
                }
              }];
            }
            get actionsIcon() {
              return "plus";
            }
            get links() {
              return this.sectionLinks;
            }
            get displaySection() {
              return this.chatStateManager.hasPreloadedChannels && (this.sectionLinks.length > 0 || this.userCanDirectMessage);
            }
          };
          return SidebarChatDirectMessagesSection;
        }, "chat");
      });
    }
  };
});