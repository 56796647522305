define("discourse/plugins/chat/discourse/templates/chat-index", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <ChannelsList />
  */
  {
    "id": "Okro16df",
    "block": "[[[8,[39,0],null,null,null]],[],false,[\"channels-list\"]]",
    "moduleName": "discourse/plugins/chat/discourse/templates/chat-index.hbs",
    "isStrictMode": false
  });
});