define("discourse/plugins/chat/discourse/admin-chat-route-map", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    resource: "admin.adminPlugins.show",
    path: "/plugins",
    map() {
      this.route("discourse-chat-incoming-webhooks", {
        path: "hooks"
      }, function () {
        this.route("new");
        this.route("show", {
          path: "/:id"
        });
      });
    }
  };
});