define("discourse/plugins/chat/discourse/modifiers/chat/track-message-separator-date", ["exports", "@ember/destroyable", "ember-modifier"], function (_exports, _destroyable, _emberModifier) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const IS_PINNED_CLASS = "is-pinned";

  /*
    This modifier is used to track the date separator in the chat message list.
    The trick is to have an element with `top: -1px` which will stop fully intersecting
    as soon as it's scrolled a little bit.
  */
  class ChatTrackMessageSeparatorDate extends _emberModifier.default {
    constructor(owner, args) {
      super(owner, args);
      (0, _destroyable.registerDestructor)(this, instance => instance.cleanup());
    }
    modify(element) {
      this.intersectionObserver = new IntersectionObserver(_ref => {
        let [entry] = _ref;
        if (entry.isIntersecting && entry.intersectionRatio < 1 && entry.boundingClientRect.y < entry.intersectionRect.y) {
          entry.target.classList.add(IS_PINNED_CLASS);
        } else {
          entry.target.classList.remove(IS_PINNED_CLASS);
        }
      }, {
        threshold: [0, 1]
      });
      this.intersectionObserver.observe(element);
    }
    cleanup() {
      this.intersectionObserver?.disconnect();
    }
  }
  _exports.default = ChatTrackMessageSeparatorDate;
});