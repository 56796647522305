define("discourse/plugins/chat/discourse/components/user-menu/chat-notifications-list", ["exports", "discourse/components/user-menu/notifications-list"], function (_exports, _notificationsList) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class UserMenuChatNotificationsList extends _notificationsList.default {
    get dismissTypes() {
      return this.filterByTypes;
    }
    get emptyStateComponent() {
      return "user-menu/chat-notifications-list-empty-state";
    }
  }
  _exports.default = UserMenuChatNotificationsList;
});