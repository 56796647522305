define("discourse/plugins/chat/discourse/preferences-chat-route-map", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    resource: "user.preferences",
    map() {
      this.route("chat");
    }
  };
});