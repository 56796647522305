define("discourse/plugins/chat/discourse/lib/get-reaction-text", ["exports", "discourse-i18n"], function (_exports, _discourseI18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.MAX_DISPLAYED_USERNAMES = void 0;
  _exports.getReactionText = getReactionText;
  const MAX_DISPLAYED_USERNAMES = _exports.MAX_DISPLAYED_USERNAMES = 15;
  function filterUsernames(users, currentUser) {
    return users.filter(user => user.id !== currentUser?.id).slice(0, MAX_DISPLAYED_USERNAMES).mapBy("username");
  }
  function reactionIncludingCurrentUser(reaction, currentUser) {
    if (reaction.count === 1) {
      return _discourseI18n.default.t("chat.reactions.only_you", {
        emoji: reaction.emoji
      });
    }
    const usernames = filterUsernames(reaction.users, currentUser);
    if (reaction.count === 2) {
      return _discourseI18n.default.t("chat.reactions.you_and_single_user", {
        emoji: reaction.emoji,
        username: usernames.pop()
      });
    }

    // - 1 for "you"
    const unnamedUserCount = reaction.count - usernames.length - 1;
    if (unnamedUserCount > 0) {
      return _discourseI18n.default.t("chat.reactions.you_multiple_users_and_more", {
        emoji: reaction.emoji,
        commaSeparatedUsernames: joinUsernames(usernames),
        count: unnamedUserCount
      });
    }
    return _discourseI18n.default.t("chat.reactions.you_and_multiple_users", {
      emoji: reaction.emoji,
      username: usernames.pop(),
      commaSeparatedUsernames: joinUsernames(usernames)
    });
  }
  function reactionText(reaction, currentUser) {
    const usernames = filterUsernames(reaction.users, currentUser);
    if (reaction.count === 1) {
      return _discourseI18n.default.t("chat.reactions.single_user", {
        emoji: reaction.emoji,
        username: usernames.pop()
      });
    }
    const unnamedUserCount = reaction.count - usernames.length;
    if (unnamedUserCount > 0) {
      return _discourseI18n.default.t("chat.reactions.multiple_users_and_more", {
        emoji: reaction.emoji,
        commaSeparatedUsernames: joinUsernames(usernames),
        count: unnamedUserCount
      });
    }
    return _discourseI18n.default.t("chat.reactions.multiple_users", {
      emoji: reaction.emoji,
      username: usernames.pop(),
      commaSeparatedUsernames: joinUsernames(usernames)
    });
  }
  function joinUsernames(usernames) {
    return usernames.join(_discourseI18n.default.t("word_connector.comma"));
  }
  function getReactionText(reaction, currentUser) {
    if (reaction.count === 0) {
      return;
    }
    if (reaction.reacted) {
      return reactionIncludingCurrentUser(reaction, currentUser);
    } else {
      return reactionText(reaction, currentUser);
    }
  }
});