define("discourse/plugins/chat/discourse/adapters/chat-message", ["exports", "discourse/adapters/rest"], function (_exports, _rest) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class ChatMessage extends _rest.default {
    pathFor(store, type, findArgs) {
      if (findArgs.targetMessageId) {
        return `/chat/lookup/${findArgs.targetMessageId}.json?chat_channel_id=${findArgs.channelId}`;
      }
      let path = `/chat/${findArgs.channelId}/messages.json?page_size=${findArgs.pageSize}`;
      if (findArgs.messageId) {
        path += `&message_id=${findArgs.messageId}`;
      }
      if (findArgs.direction) {
        path += `&direction=${findArgs.direction}`;
      }
      return path;
    }
    apiNameFor() {
      return "chat-message";
    }
  }
  _exports.default = ChatMessage;
});