define("discourse/plugins/chat/discourse/lib/chat-constants", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.THREAD_TITLE_PROMPT_THRESHOLD = _exports.READ_INTERVAL_MS = _exports.PAST = _exports.INDICATOR_PREFERENCES = _exports.FUTURE = _exports.FOOTER_NAV_ROUTES = _exports.DEFAULT_MESSAGE_PAGE_SIZE = void 0;
  const PAST = _exports.PAST = "past";
  const FUTURE = _exports.FUTURE = "future";
  const READ_INTERVAL_MS = _exports.READ_INTERVAL_MS = 1000;
  const DEFAULT_MESSAGE_PAGE_SIZE = _exports.DEFAULT_MESSAGE_PAGE_SIZE = 50;
  const THREAD_TITLE_PROMPT_THRESHOLD = _exports.THREAD_TITLE_PROMPT_THRESHOLD = 5;
  const FOOTER_NAV_ROUTES = _exports.FOOTER_NAV_ROUTES = ["chat.direct-messages", "chat.channels", "chat.threads"];
  const INDICATOR_PREFERENCES = _exports.INDICATOR_PREFERENCES = {
    all_new: "all_new",
    dm_and_mentions: "dm_and_mentions",
    only_mentions: "only_mentions",
    never: "never"
  };
});