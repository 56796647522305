define("discourse/plugins/chat/discourse/components/chat/message-creator/constants", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.MODES = void 0;
  const MODES = _exports.MODES = {
    search: "SEARCH",
    new_group: "NEW_GROUP",
    add_members: "ADD_MEMBERS"
  };
});