define("discourse/plugins/chat/discourse/lib/chat-message-container", ["exports", "discourse/plugins/chat/discourse/components/chat-message"], function (_exports, _chatMessage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = chatMessageContainer;
  function chatMessageContainer(id, context) {
    let selector;
    if (context === _chatMessage.MESSAGE_CONTEXT_THREAD) {
      selector = `.chat-thread .chat-message-container[data-id="${id}"]`;
    } else {
      selector = `.chat-channel .chat-message-container[data-id="${id}"]`;
    }
    return document.querySelector(selector);
  }
});