define("discourse/plugins/chat/discourse/initializers/chat-plugin-decorators", ["exports", "discourse/lib/local-dates", "discourse/lib/plugin-api"], function (_exports, _localDates, _pluginApi) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    name: "chat-plugin-decorators",
    initializeWithPluginApi(api, siteSettings) {
      api.decorateChatMessage(element => {
        (0, _localDates.applyLocalDates)(element.querySelectorAll(".discourse-local-date"), siteSettings);
      }, {
        id: "local-dates"
      });
      if (siteSettings.spoiler_enabled) {
        const applySpoiler = requirejs("discourse/plugins/spoiler-alert/lib/apply-spoiler").default;
        api.decorateChatMessage(element => {
          element.querySelectorAll(".spoiler").forEach(spoiler => {
            spoiler.classList.remove("spoiler");
            spoiler.classList.add("spoiled");
            applySpoiler(spoiler);
          });
        }, {
          id: "spoiler"
        });
      }
    },
    initialize(container) {
      if (container.lookup("service:chat").userCanChat) {
        const siteSettings = container.lookup("service:site-settings");
        (0, _pluginApi.withPluginApi)("0.8.42", api => {
          this.initializeWithPluginApi(api, siteSettings);
        });
      }
    }
  };
});