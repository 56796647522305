define("discourse/plugins/chat/discourse/components/chat/modal/delete-messages-confirm", ["exports", "@glimmer/component", "@ember/object", "@ember/service", "discourse/components/d-button", "discourse/components/d-modal", "discourse/components/d-modal-cancel", "discourse/lib/ajax-error", "discourse-common/helpers/i18n", "@ember/component", "@ember/template-factory"], function (_exports, _component, _object, _service, _dButton, _dModal, _dModalCancel, _ajaxError, _i18n, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class DeleteMessagesConfirm extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "chatApi", [_service.service]))();
    #chatApi = (() => (dt7948.i(this, "chatApi"), void 0))();
    async delete() {
      try {
        await this.chatApi.trashMessages(this.args.model.sourceChannel.id, this.args.model.selectedMessageIds);
      } catch (error1) {
        (0, _ajaxError.popupAjaxError)(error1);
      } finally {
        this.args.closeModal();
      }
    }
    static #_2 = (() => dt7948.n(this.prototype, "delete", [_object.action]))();
    static #_3 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <DModal @closeModal={{@closeModal}} @headerClass="hidden">
          <:body>
            {{i18n
              "chat.delete_messages.confirm"
              count=@model.selectedMessageIds.length
            }}
          </:body>
          <:footer>
            <DButton
              class="btn-primary"
              @action={{this.delete}}
              @label="delete"
              @icon="trash-can"
            />
            <DModalCancel @close={{@closeModal}} />
          </:footer>
        </DModal>
      
    */
    {
      "id": "Uybvpxw2",
      "block": "[[[1,\"\\n    \"],[8,[32,0],null,[[\"@closeModal\",\"@headerClass\"],[[30,1],\"hidden\"]],[[\"body\",\"footer\"],[[[[1,\"\\n        \"],[1,[28,[32,1],[\"chat.delete_messages.confirm\"],[[\"count\"],[[30,2,[\"selectedMessageIds\",\"length\"]]]]]],[1,\"\\n      \"]],[]],[[[1,\"\\n        \"],[8,[32,2],[[24,0,\"btn-primary\"]],[[\"@action\",\"@label\",\"@icon\"],[[30,0,[\"delete\"]],\"delete\",\"trash-can\"]],null],[1,\"\\n        \"],[8,[32,3],null,[[\"@close\"],[[30,1]]],null],[1,\"\\n      \"]],[]]]]],[1,\"\\n  \"]],[\"@closeModal\",\"@model\"],false,[]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/chat/discourse/components/chat/modal/delete-messages-confirm.js",
      "scope": () => [_dModal.default, _i18n.default, _dButton.default, _dModalCancel.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = DeleteMessagesConfirm;
});