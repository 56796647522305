define("discourse/plugins/chat/discourse/lib/slugify-channel", ["exports", "discourse/lib/utilities"], function (_exports, _utilities) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = slugifyChannel;
  function slugifyChannel(channel) {
    if (channel.slug) {
      return channel.slug;
    }
    if (!channel.escapedTitle && !channel.title) {
      return "-";
    }
    const slug = (0, _utilities.slugify)(channel.escapedTitle || channel.title);
    const resolvedSlug = (slug.length ? slug : channel.title.trim().toLowerCase().replace(/\s|_+/g, "-")).slice(0, 100);
    if (!resolvedSlug) {
      return "-";
    }
    return resolvedSlug;
  }
});