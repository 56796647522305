define("discourse/plugins/chat/discourse/templates/connectors/below-footer/chat-channel-message-emoji-picker-connector", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <ChatChannelMessageEmojiPicker />
  */
  {
    "id": "yzyIVLUg",
    "block": "[[[8,[39,0],null,null,null]],[],false,[\"chat-channel-message-emoji-picker\"]]",
    "moduleName": "discourse/plugins/chat/discourse/templates/connectors/below-footer/chat-channel-message-emoji-picker-connector.hbs",
    "isStrictMode": false
  });
});