define("discourse/plugins/chat/discourse/models/chat-tracking-state", ["exports", "@glimmer/tracking", "@ember/owner", "@ember/service"], function (_exports, _tracking, _owner, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class ChatTrackingState {
    static #_ = (() => dt7948.g(this.prototype, "chatTrackingStateManager", [_service.service]))();
    #chatTrackingStateManager = (() => (dt7948.i(this, "chatTrackingStateManager"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "_unreadCount", [_tracking.tracked]))();
    #_unreadCount = (() => (dt7948.i(this, "_unreadCount"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "_mentionCount", [_tracking.tracked]))();
    #_mentionCount = (() => (dt7948.i(this, "_mentionCount"), void 0))();
    static #_4 = (() => dt7948.g(this.prototype, "_watchedThreadsUnreadCount", [_tracking.tracked]))();
    #_watchedThreadsUnreadCount = (() => (dt7948.i(this, "_watchedThreadsUnreadCount"), void 0))();
    constructor(owner) {
      let params = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      (0, _owner.setOwner)(this, owner);
      this._unreadCount = params.unreadCount ?? 0;
      this._mentionCount = params.mentionCount ?? 0;
      this._watchedThreadsUnreadCount = params.watchedThreadsUnreadCount ?? 0;
    }
    reset() {
      this._unreadCount = 0;
      this._mentionCount = 0;
      this._watchedThreadsUnreadCount = 0;
    }
    get unreadCount() {
      return this._unreadCount;
    }
    set unreadCount(value) {
      const valueChanged = this._unreadCount !== value;
      if (valueChanged) {
        this._unreadCount = value;
        this.chatTrackingStateManager.triggerNotificationsChanged();
      }
    }
    get mentionCount() {
      return this._mentionCount;
    }
    set mentionCount(value) {
      const valueChanged = this._mentionCount !== value;
      if (valueChanged) {
        this._mentionCount = value;
        this.chatTrackingStateManager.triggerNotificationsChanged();
      }
    }
    get watchedThreadsUnreadCount() {
      return this._watchedThreadsUnreadCount;
    }
    set watchedThreadsUnreadCount(value) {
      const valueChanged = this._watchedThreadsUnreadCount !== value;
      if (valueChanged) {
        this._watchedThreadsUnreadCount = value;
        this.chatTrackingStateManager.triggerNotificationsChanged();
      }
    }
  }
  _exports.default = ChatTrackingState;
});